import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from '@react-spectrum/icon';
export function SpectrumFontAwesomeIcon(props) {
    return _jsx(Icon, Object.assign({ size: props.size }, { children: _jsx("svg", Object.assign({ viewBox: '0 0 ' + props.icon.icon[0] + ' ' + props.icon.icon[1] }, { children: createPaths(props.icon.icon[4]) })) }));
}
function createPaths(path) {
    if (Array.isArray(path)) {
        return path.map(v => _jsx("path", { d: v }));
    }
    else {
        return _jsx("path", { d: path });
    }
}
