export var PersonenArt;
(function (PersonenArt) {
    PersonenArt[PersonenArt["OE"] = 0] = "OE";
    PersonenArt[PersonenArt["PERSON"] = 1] = "PERSON";
})(PersonenArt || (PersonenArt = {}));
export var SourceReferenceType;
(function (SourceReferenceType) {
    SourceReferenceType[SourceReferenceType["PERSON"] = 0] = "PERSON";
    SourceReferenceType[SourceReferenceType["AKTE"] = 1] = "AKTE";
    SourceReferenceType[SourceReferenceType["NACHRICHT"] = 2] = "NACHRICHT";
})(SourceReferenceType || (SourceReferenceType = {}));
export var DetailSourceReferenceType;
(function (DetailSourceReferenceType) {
    DetailSourceReferenceType[DetailSourceReferenceType["DOKUMENT"] = 0] = "DOKUMENT";
    DetailSourceReferenceType[DetailSourceReferenceType["NACHRICHT"] = 1] = "NACHRICHT";
})(DetailSourceReferenceType || (DetailSourceReferenceType = {}));
