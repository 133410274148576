var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ObjectProperty, ObservableArray } from '@baeso-ui/baeso-observable';
export class EAktenSelectionVM {
    constructor(eaktenService) {
        this.aktenList = new ObservableArray('aktenList', []);
        this.selectedAkte = new ObjectProperty('selectedAkte', undefined);
        this.akten = this.aktenList;
        this.recentlyOpenedVM = new EAktenSelectionRecentlyOpenedVM();
        this.eaktenService = eaktenService;
        this.loadData();
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            const aktenItems = yield this.eaktenService.listAkten();
            this.aktenList.setAll(aktenItems);
            this.recentlyOpenedVM.removeNonexistentAkten(aktenItems);
        });
    }
    refresh() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loadData();
        });
    }
}
const jsonRecentlyOpenedItemReviver = (key, value) => {
    if (key === 'time' && typeof value === 'string') {
        return new Date(value);
    }
    else {
        return value;
    }
};
export class EAktenSelectionRecentlyOpenedVM {
    constructor() {
        this.aktenList = new ObservableArray('recentlyOpenedAktenList', []);
        this.akten = this.aktenList;
        this.loadData();
        this.aktenList.onValueChange(value => window.localStorage.setItem(EAktenSelectionRecentlyOpenedVM.RECENTLY_OPENED_STORAGE_KEY, JSON.stringify(value)));
    }
    loadData() {
        const storedValue = window.localStorage.getItem(EAktenSelectionRecentlyOpenedVM.RECENTLY_OPENED_STORAGE_KEY);
        const recentlyOpened = storedValue ? JSON.parse(storedValue, jsonRecentlyOpenedItemReviver) : [];
        this.aktenList.setAll(recentlyOpened);
    }
    markRecentlyOpened(item) {
        return __awaiter(this, void 0, void 0, function* () {
            const existingIndex = this.aktenList.findIndex(i => i.item.id === item.id);
            if (existingIndex >= 0) {
                this.aktenList.removeAt(existingIndex);
            }
            const newItemCount = this.aktenList.unshift({
                item: item,
                time: new Date(),
            });
            if (newItemCount > EAktenSelectionRecentlyOpenedVM.MAX_RECENTLY_OPENED_COUNT) {
                this.aktenList.pop();
            }
        });
    }
    removeNonexistentAkten(aktenItems) {
        const removedRecentlyOpenedAkten = this.aktenList.value.filter(item => aktenItems.find(i => i.id === item.item.id) === undefined);
        removedRecentlyOpenedAkten.forEach(i => this.aktenList.remove(i));
    }
    monitorRecentlyOpenedAkten() {
        this.loadData();
        const storageEventListener = (event) => {
            if (event.key === EAktenSelectionRecentlyOpenedVM.RECENTLY_OPENED_STORAGE_KEY) {
                if (event.newValue !== null) {
                    this.aktenList.setAll(JSON.parse(event.newValue, jsonRecentlyOpenedItemReviver));
                }
                else {
                    this.aktenList.clear();
                }
            }
        };
        window.addEventListener('storage', storageEventListener);
        return () => window.removeEventListener('storage', storageEventListener);
    }
}
EAktenSelectionRecentlyOpenedVM.MAX_RECENTLY_OPENED_COUNT = 10;
EAktenSelectionRecentlyOpenedVM.RECENTLY_OPENED_STORAGE_KEY = 'onacta-veakten-recentlyOpenedAkten';
