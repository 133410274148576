var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BooleanProperty, ObjectProperty } from '@baeso-ui/baeso-observable';
import { CheckBoxVM, DropDownVM, TextInputVM } from '@baeso-ui/baeso-vm';
import { last, pushAll, treeStructure } from '@baeso-ui/baeso-core';
import { toListItems } from '@onacta/onacta-base-vm';
import { Position } from '@onacta/onacta-veakten-services';
export class UploadVeraktungsVM {
    constructor(veraktenService, pdfService, aufgabenCreator, aktenId, preselectedAktenteilId, preselectedBandId) {
        this.uploadVM = new DokumentUploadVM(aktenId, veraktenService, pdfService);
        this.veraktenVM = new VeraktungsViewPanelVM(veraktenService, pdfService, this.uploadVM.document, aufgabenCreator, aktenId, preselectedAktenteilId, preselectedBandId);
    }
}
export class NachrichtVeraktungsVM {
    constructor(veraktenService, pdfService, aufgabenCreator, aktenId, postfachId, nachrichtenId, dokumentName) {
        this.preparationVM = new NachrichtPreparationVM(aktenId, veraktenService, pdfService, postfachId, nachrichtenId, dokumentName);
        this.veraktenVM = new VeraktungsViewPanelVM(veraktenService, pdfService, this.preparationVM.document, aufgabenCreator, aktenId);
    }
}
export class VeraktungsViewPanelVM {
    constructor(viewService, pdfService, document, aufgabenCreator, aktenId, preselectedAktenteilId, preselectedBandId) {
        this.data = undefined;
        this.viewService = viewService;
        this.pdfService = pdfService;
        this.document = document;
        this.aktenId = aktenId;
        this.positionUndPaginierungVM = new PositionUndPaginierungVM(document, preselectedAktenteilId, preselectedBandId);
        this.dokumentEigenschaftenVM = new DokumentEigenschaftenVM(document, this.positionUndPaginierungVM.isVeraktungPaginiert);
        this.aufgabenCreator = aufgabenCreator;
        this.initData();
    }
    initData() {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield this.viewService.getBaseData(this.aktenId);
            this.data = data;
            this.positionUndPaginierungVM.initData(data);
            this.dokumentEigenschaftenVM.initData(data);
        });
    }
    speichern() {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const preparedDokument = (_a = this.document.value) === null || _a === void 0 ? void 0 : _a.preparedDoc;
            const bandId = (_b = this.positionUndPaginierungVM.band.value.value) === null || _b === void 0 ? void 0 : _b.key;
            const dokumentName = this.dokumentEigenschaftenVM.dokumentName.value.value;
            const dokumentTyp = (_c = this.dokumentEigenschaftenVM.dokumentTyp.value.value) === null || _c === void 0 ? void 0 : _c.key;
            // check common data required for all Veraktungs-types
            if (preparedDokument === undefined
                || bandId === undefined
                || dokumentName === undefined || dokumentName.trim().length == 0
                || dokumentTyp === undefined
                || this.data === undefined) {
                return Promise.reject('Fehlende daten');
            }
            const bandItem = this.data.aktenteilListe.flatMap(at => at.baende).find(item => item.id === bandId);
            if (bandItem === undefined) {
                // should never occur in practice if inputs were setup properly
                // (should be only able to select a Band which really exists)
                return Promise.reject('Fehlende daten');
            }
            const band = { idRef: bandItem.id, etag: bandItem.etag };
            const veraktungsData = bandItem.paginiert
                ? this.createPaginiertVeraktung(band, dokumentName, { idRef: dokumentTyp })
                : this.createUnpaginiertVeraktung(band, dokumentName, { idRef: dokumentTyp });
            if (veraktungsData === undefined) {
                return Promise.reject('Fehlende daten');
            }
            const newDokId = yield this.viewService.preparedVerakten(veraktungsData, preparedDokument);
            yield this.aufgabenCreator.execute(newDokId);
            return newDokId;
        });
    }
    createPaginiertVeraktung(band, dokumentName, dokumentTyp) {
        var _a;
        const position = (_a = this.positionUndPaginierungVM.position.value.value) === null || _a === void 0 ? void 0 : _a.key;
        const referenzDokumenteCount = this.positionUndPaginierungVM.refDokument.items.value.length;
        const referenzDokument = this.findReferenzDokument();
        if (position === undefined || (referenzDokumenteCount > 0 && referenzDokument === undefined)) {
            return undefined;
        }
        else {
            const data = {
                aktenId: this.aktenId,
                band,
                dokumentName,
                dokumentTyp,
                position: toPos(position),
                referenzDokument: referenzDokument,
            };
            return { veraktungPaginiert: data };
        }
    }
    findReferenzDokument() {
        var _a;
        const referenzDokumentId = (_a = this.positionUndPaginierungVM.refDokument.value.value) === null || _a === void 0 ? void 0 : _a.key;
        if (referenzDokumentId) {
            const dok = this.positionUndPaginierungVM.nodeMap.get(referenzDokumentId);
            if (dok) {
                return { idRef: dok.item.id, etag: dok.item.etag };
            }
        }
        return undefined;
    }
    createUnpaginiertVeraktung(band, dokumentName, dokumentTyp) {
        const data = {
            aktenId: this.aktenId,
            band,
            dokumentName,
            dokumentTyp,
        };
        return { veraktungUnpaginiert: data };
    }
}
function toPos(value) {
    switch (value) {
        case '1': return Position.NACH;
        case '2': return Position.VOR;
        case '3': return Position.VERBINDEN;
        case '4': return Position.UNTER;
        case '5': return Position.UNTER_VERBINDEN;
        default: return Position.NACH;
    }
}
export class PositionUndPaginierungVM {
    constructor(document, preselectedAktenteilId, preselectedBandId) {
        this.refDokumentDisabled = new BooleanProperty('refDokumentDisabled', true);
        this.isVeraktungPaginiert = new BooleanProperty('isVeraktungPaginiert', false);
        this.rootDokumente = [];
        this.nodeMap = new Map();
        this.aktenteil = new DropDownVM([]);
        this.band = new DropDownVM([]);
        this.refDokument = new DropDownVM({ disabled: this.refDokumentDisabled, items: [] });
        this.position = new DropDownVM({
            required: this.isVeraktungPaginiert,
            disabled: this.refDokumentDisabled,
            items: [
                { key: '1', text: 'nach Dokument' },
                { key: '2', text: 'vor Dokument' },
                { key: '3', text: 'als verbundenes Dokument' },
                { key: '4', text: 'als Unterdokument' },
                // new VMItem("5","als verbundenes Unterdokument")
            ]
        });
        this.anzahlSeite = new TextInputVM({ editable: false });
        this.paginierungErsteSeite = new TextInputVM({ editable: false, disabled: this.refDokumentDisabled });
        this.preselectedAktenteilId = preselectedAktenteilId;
        this.preselectedBandId = preselectedBandId;
        this.newDocument = document;
        this.position.value.value = this.position.items.value[0];
        this.refDokument.value.onValueChange(this.updatePaginierungsInfo.bind(this));
        this.position.value.onValueChange(this.updatePaginierungsInfo.bind(this));
        this.aktenteil.value.onValueChange(this.handleAktenteilChange.bind(this));
        this.band.value.onValueChange(this.handleBandChange.bind(this));
        this.newDocument.onValueChange(this.handleDocumentChange.bind(this));
    }
    handleDocumentChange(doc) {
        if ((doc === null || doc === void 0 ? void 0 : doc.pdfDocument) !== undefined) {
            this.anzahlSeite.value.value = doc.pdfDocument.pages.length + '';
            this.anzahlSeite.disabled.value = false;
        }
        else {
            this.anzahlSeite.value.value = undefined;
            this.anzahlSeite.disabled.value = true;
        }
        this.updateAktenteilBandLists();
    }
    handleAktenteilChange(newElement) {
        if (newElement && newElement.children && newElement.children.length > 0) {
            this.isVeraktungPaginiert.value = newElement.paginiert;
            this.band.items.value = newElement.children;
            this.band.value.value = last(this.band.items.value);
            this.band.disabled.value = false;
        }
        else {
            this.band.items.value = [];
            this.band.value.value = undefined;
            this.band.disabled.value = true;
        }
    }
    handleBandChange(ne) {
        if (ne && ne.children && ne.children.length > 0) {
            this.refDokument.items.value = ne.children;
            this.refDokument.value.value = last(this.refDokument.items.value);
            this.refDokument.required.value = true;
        }
        else {
            this.refDokument.items.value = [];
            this.refDokument.value.value = undefined;
            this.refDokument.required.value = false;
        }
        this.refDokumentDisabled.value = !this.isVeraktungPaginiert.value;
    }
    updatePaginierungsInfo() {
        if (this.viewBaseData && this.position.value.value &&
            this.aktenteil.value.value && this.refDokument.value.value) {
            const doc = this.nodeMap.get(this.refDokument.value.value.key);
            if (doc) {
                if (this.position.value.value.key == '1' || this.position.value.value.key == '3') {
                    if (this.position.value.value.key == '1' || this.position.value.value.key == '3') {
                        this.paginierungErsteSeite.value.value = this.numberToPaginierung(doc.level, doc.item.paginierungEnde + 1);
                    }
                }
                else if (this.position.value.value.key == '2') {
                    this.paginierungErsteSeite.value.value = this.numberToPaginierung(doc.level, doc.item.paginierungStart);
                }
                else {
                    const lastDok = last(doc.children);
                    if (lastDok) {
                        this.paginierungErsteSeite.value.value = this.numberToPaginierung(doc.level + 1, lastDok.item.paginierungEnde + 1);
                    }
                    else {
                        this.paginierungErsteSeite.value.value = this.numberToPaginierung(doc.level + 1, 1);
                    }
                }
            }
            else {
                this.paginierungErsteSeite.value.value = 'Err';
            }
        }
        else {
            this.paginierungErsteSeite.value.value = '1';
        }
    }
    numberToPaginierung(level, pageNumber) {
        if (level === 1) {
            return pageNumber + '';
        }
        else {
            return this.convertToRoman(pageNumber);
        }
    }
    convertToRoman(num) {
        const romanToDec = [
            ['M', 1000],
            ['CM', 900],
            ['D', 500],
            ['CD', 400],
            ['C', 100],
            ['XC', 90],
            ['L', 50],
            ['XL', 40],
            ['X', 10],
            ['IX', 9],
            ['V', 5],
            ['IV', 4],
            ['I', 1]
        ];
        let rv = '';
        romanToDec.forEach(mapping => {
            while (num >= mapping[1]) {
                rv += mapping[0];
                num -= mapping[1];
            }
        });
        return rv;
    }
    flatList(items) {
        const rv = [];
        for (let i = 0; i < items.length; i++) {
            rv.push(items[i]);
            pushAll(rv, this.flatList(items[i].children));
        }
        return rv;
    }
    initData(data) {
        this.viewBaseData = data;
        this.rootDokumente = treeStructure(data.paginierteDokumenteListe, dok => dok.unterDokumente);
        this.updateAktenteilBandLists();
    }
    updateAktenteilBandLists() {
        if (this.viewBaseData === undefined || this.newDocument.value === undefined) {
            return;
        }
        const baseData = this.viewBaseData;
        const uploadDoc = this.newDocument.value;
        const allNodes = this.flatList(this.rootDokumente);
        allNodes.forEach(n => this.nodeMap.set(n.item.id, n));
        const items = baseData.aktenteilListe
            .filter(at => uploadDoc.pdfDocument !== undefined || !at.paginiert) // PDFs can be uploaded to any Aktenteil, other files only to unpaginiert
            .map(a => {
            const baende = a.baende.map(b => {
                const dokumente = allNodes
                    .filter(d => d.item.bandId == b.id)
                    .map(d => {
                    const description = (d.item.verbunden ? 'verbunden - ' : '') + d.item.anzahlSeiten + ' Seiten - Paginierung: ' + this.numberToPaginierung(d.level, d.item.paginierungStart) + ' - ' + this.numberToPaginierung(d.level, d.item.paginierungEnde);
                    return { key: d.item.id, text: d.item.name, description: description };
                });
                return {
                    children: dokumente,
                    key: b.id,
                    text: b.name,
                };
            });
            return {
                children: baende,
                key: a.id,
                text: a.name,
                paginiert: a.paginiert,
            };
        });
        this.aktenteil.items.value = items;
        const selectedAktenteil = this.preselectedAktenteilId ? items.find(a => a.key == this.preselectedAktenteilId) : undefined;
        this.aktenteil.value.value = selectedAktenteil;
        if (selectedAktenteil === null || selectedAktenteil === void 0 ? void 0 : selectedAktenteil.children) {
            this.band.items.value = selectedAktenteil.children;
            this.band.value.value = this.preselectedBandId ? selectedAktenteil.children.find(b => b.key == this.preselectedBandId) : undefined;
        }
    }
}
export class DokumentEigenschaftenVM {
    constructor(document, isVeraktungPaginiert) {
        this.dokumentName = new TextInputVM();
        this.dokumentTyp = new DropDownVM([]);
        this.vorVernichungEntfernen = new CheckBoxVM();
        this.beschraenkteAnsicht = new CheckBoxVM();
        this.anzeigeImZeitstrahl = new CheckBoxVM();
        this.dokumentName.required.value = true;
        document.onValueChange(doc => {
            this.dokumentName.value.value = doc === null || doc === void 0 ? void 0 : doc.documentName;
        });
        isVeraktungPaginiert.onValueChange(this.handleVeraktungPaginiertChange.bind(this));
        this.handleVeraktungPaginiertChange(isVeraktungPaginiert.value);
    }
    handleVeraktungPaginiertChange(isPaginiert) {
        this.vorVernichungEntfernen.disabled.value = !isPaginiert;
        this.beschraenkteAnsicht.disabled.value = !isPaginiert;
        this.anzeigeImZeitstrahl.disabled.value = !isPaginiert;
    }
    initData(data) {
        this.dokumentTyp.items.value = toListItems(data.dokumentTypListe);
        if (this.dokumentTyp.items.value.length > 0) {
            this.dokumentTyp.value.value = last(this.dokumentTyp.items.value);
        }
    }
}
class DokumentPreparationVMBase {
    constructor(aktenId, veraktenService, pdfService) {
        this.aktenId = aktenId;
        this.veraktenService = veraktenService;
        this.pdfService = pdfService;
        this.document = new ObjectProperty('document');
        this.uploading = new BooleanProperty('uploading', false);
    }
}
export class DokumentUploadVM extends DokumentPreparationVMBase {
    constructor(aktenId, veraktenService, pdfService) {
        super(aktenId, veraktenService, pdfService);
        this.ensurePDF = new BooleanProperty('ensurePDF', false);
    }
    upload(file) {
        return __awaiter(this, void 0, void 0, function* () {
            this.uploading.value = true;
            try {
                const preparedDoc = yield this.veraktenService.prepareDocument(this.aktenId, file, this.ensurePDF.value);
                const pdfDocument = preparedDoc.pdfHandle ? yield this.pdfService.get(preparedDoc.pdfHandle) : undefined;
                this.document.value = {
                    documentName: file.name,
                    preparedDoc,
                    pdfDocument,
                };
            }
            finally {
                this.uploading.value = false;
            }
        });
    }
    reset() {
        this.document.value = undefined;
    }
}
export class NachrichtPreparationVM extends DokumentPreparationVMBase {
    constructor(aktenId, veraktenService, pdfService, postfachId, nachrichtenId, documentName) {
        super(aktenId, veraktenService, pdfService);
        this.postfachId = postfachId;
        this.nachrichtenId = nachrichtenId;
        this.documentName = documentName;
    }
    prepare() {
        return __awaiter(this, void 0, void 0, function* () {
            this.uploading.value = true;
            try {
                const preparedDoc = yield this.veraktenService.prepareNachricht(this.aktenId, this.postfachId, this.nachrichtenId);
                const pdfDocument = preparedDoc.pdfHandle ? yield this.pdfService.get(preparedDoc.pdfHandle) : undefined;
                this.document.value = {
                    documentName: this.documentName,
                    preparedDoc,
                    pdfDocument,
                };
            }
            finally {
                this.uploading.value = false;
            }
        });
    }
}
