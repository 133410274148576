var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ObjectProperty, ObservableArray } from '@baeso-ui/baeso-observable';
export class EAkteCreationVM {
    constructor(eaktenAnlageService) {
        this.eaktenAnlageService = eaktenAnlageService;
        this.hauptgebieteVM = new HauptgebieteVM(eaktenAnlageService);
        this.gruppenVM = new GruppenVM(eaktenAnlageService, this.hauptgebieteVM.hauptgebietSelected);
        this.untergruppenVM = new UntergruppenVM(eaktenAnlageService, this.gruppenVM.itemSelected);
        this.einzelaktenVM = new EinzelaktenVM(eaktenAnlageService, this.untergruppenVM.itemSelected);
    }
    create() {
        if (this.einzelaktenVM.itemSelected.value) {
            return this.eaktenAnlageService.createAkteEinzelakte(this.einzelaktenVM.itemSelected.value.id);
        }
        else if (this.untergruppenVM.itemSelected.value) {
            return this.eaktenAnlageService.createAkteUntergruppe(this.untergruppenVM.itemSelected.value.id);
        }
        else if (this.gruppenVM.itemSelected.value) {
            return this.eaktenAnlageService.createAkteGruppe(this.gruppenVM.itemSelected.value.id);
        }
        else if (this.hauptgebieteVM.hauptgebietSelected.value) {
            return this.eaktenAnlageService.createAkteHauptgebiet(this.hauptgebieteVM.hauptgebietSelected.value.id);
        }
        else {
            throw new Error('no Ebene selected');
        }
    }
}
export class HauptgebieteVM {
    constructor(eaktenAnlageService) {
        this.hauptgebieteList = new ObservableArray('hauptgebiete', []);
        this.hauptgebiete = this.hauptgebieteList;
        this.hauptgebietSelected = new ObjectProperty('hauptgebietSelected');
        this.eaktenAnlageService = eaktenAnlageService;
        this.loadData();
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            const entries = yield this.eaktenAnlageService.getEbeneHauptgebiete();
            this.hauptgebieteList.setAll(entries);
        });
    }
}
class ChildEbenenVM {
    constructor(eaktenAnlageService, parent) {
        this.itemsList = new ObservableArray('items', []);
        this.items = this.itemsList;
        this.itemSelected = new ObjectProperty('itemSelected');
        this.eaktenAnlageService = eaktenAnlageService;
        parent.onValueChange(i => this.loadData(i));
    }
    loadData(parent) {
        return __awaiter(this, void 0, void 0, function* () {
            this.itemsList.clear();
            this.itemSelected.value = undefined;
            if (parent) {
                const entries = yield this.fetchItems(parent.id);
                this.itemsList.setAll(entries);
            }
        });
    }
}
export class GruppenVM extends ChildEbenenVM {
    fetchItems(parentId) {
        return this.eaktenAnlageService.getEbeneGruppen(parentId);
    }
}
export class UntergruppenVM extends ChildEbenenVM {
    fetchItems(parentId) {
        return this.eaktenAnlageService.getEbeneUntergruppen(parentId);
    }
}
export class EinzelaktenVM extends ChildEbenenVM {
    fetchItems(parentId) {
        return this.eaktenAnlageService.getEbeneEinzelakten(parentId);
    }
}
