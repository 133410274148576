export interface Messages {
  'onacta-main.mainpage': string,
  'onacta-main.search-page': string,
  'onacta-main.tasks': string,
  'onacta-main.mailbox': string,
  'onacta-main.file': string,

  'onacta-main.global-preferences': string,
  'onacta-main.app-info': string,

  'onacta-main.close': string,

  'onacta-main.preferences.color-theme': string,
  'onacta-main.preferences.color-theme.placeholder': string
  'onacta-main.preferences.scale': string,
  'onacta-main.preferences.scale.placeholder': string,
  'onacta-main.preferences.visiondef-emulation': string,
  'onacta-main.preferences.visiondef-emulation.placeholder': string,
  'onacta-main.preferences.darkModeDocument': string,

  'onacta-main.preferences.color-theme.standard': string,
  'onacta-main.preferences.color-theme.light': string,
  'onacta-main.preferences.color-theme.extra-light': string,
  'onacta-main.preferences.color-theme.dark': string,
  'onacta-main.preferences.color-theme.extra-dark': string,

  'onacta-main.preferences.scale.standard': string,
  'onacta-main.preferences.scale.normal': string,
  'onacta-main.preferences.scale.large': string,

  'onacta-main.preferences.visiondef-emulation.off': string,
  'onacta-main.preferences.visiondef-emulation.mono': string,
  'onacta-main.preferences.visiondef-emulation.blurred-vision': string,
  'onacta-main.preferences.visiondef-emulation.protanopia': string,
  'onacta-main.preferences.visiondef-emulation.protanomaly': string,
  'onacta-main.preferences.visiondef-emulation.deuteranopia': string,
  'onacta-main.preferences.visiondef-emulation.deuteranomaly': string,
  'onacta-main.preferences.visiondef-emulation.tritanopia': string,
  'onacta-main.preferences.visiondef-emulation.tritanomaly': string,
  'onacta-main.preferences.visiondef-emulation.achromatopsia': string,
  'onacta-main.preferences.visiondef-emulation.achromatomaly': string,
}


const german = {
  'onacta-main.mainpage': 'Hauptseite',
  'onacta-main.search-page': 'Suchkomponente öffnen',
  'onacta-main.tasks': 'Aktuelle Aufgaben und Termine öffnen',
  'onacta-main.mailbox': 'Nachrichtenkomponente öffnen',
  'onacta-main.file': 'Aktenblock öffnen',

  'onacta-main.global-preferences': 'Globale Einstellungen',
  'onacta-main.app-info': 'Über',

  'onacta-main.close': 'Schließen',

  'onacta-main.preferences.color-theme': 'Farbthema',
  'onacta-main.preferences.color-theme.standard': 'Standard',
  'onacta-main.preferences.color-theme.light': 'Hell',
  'onacta-main.preferences.color-theme.extra-light': 'Extra-Hell',
  'onacta-main.preferences.color-theme.dark': 'Dunkel',
  'onacta-main.preferences.color-theme.extra-dark': 'Extra-Dunkel',
  'onacta-main.preferences.color-theme.placeholder': 'Auswahl Farbthema',

  'onacta-main.preferences.scale': 'Skalierung',
  'onacta-main.preferences.scale.standard': 'Standard',
  'onacta-main.preferences.scale.normal': 'Normal',
  'onacta-main.preferences.scale.large': 'Vergrößert',
  'onacta-main.preferences.scale.placeholder': 'Auswahl Skalierung',

  'onacta-main.preferences.visiondef-emulation': 'Fehlsichtigkeitsemulation',
  'onacta-main.preferences.visiondef-emulation.placeholder': 'Auswahl Fehlsichtigkeitsemulation',
  'onacta-main.preferences.visiondef-emulation.off': 'Keine',
  'onacta-main.preferences.visiondef-emulation.mono': 'Graustufen',
  'onacta-main.preferences.visiondef-emulation.blurred-vision': 'Verschwommen',
  'onacta-main.preferences.visiondef-emulation.protanopia': 'Rotblindheit - Protanopia',
  'onacta-main.preferences.visiondef-emulation.protanomaly': 'Rotblindheit - Protanomaly',
  'onacta-main.preferences.visiondef-emulation.deuteranopia': 'Grünblindheit - Deuteranopia',
  'onacta-main.preferences.visiondef-emulation.deuteranomaly': 'Grünblindheit - Deuteranomaly',
  'onacta-main.preferences.visiondef-emulation.tritanopia': 'Blau-Gelb-Sehschwäche - Tritanopia',
  'onacta-main.preferences.visiondef-emulation.tritanomaly': 'Blau-Gelb-Sehschwäche - Tritanomaly',
  'onacta-main.preferences.visiondef-emulation.achromatopsia': 'Monochromasie - Achromatopsia',
  'onacta-main.preferences.visiondef-emulation.achromatomaly': 'Monochromasie - Achromatomaly',

  'onacta-main.preferences.darkModeDocument': 'Dunkel Modus auch auf Dokumente anwenden',
};

export const messages = {
  'en-US': german,
  'de-AT': german,
  'de-DE': german,
  'de-CH': german
};