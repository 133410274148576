import { lazy } from 'react';

import { Consumer, ServiceRegistry } from '@baeso-ui/baeso-core';

import { AufgabeCreationVM, AufgabeMultiCreationVM, AufgabenListBySourceOverviewVM } from '@onacta/onacta-vaufgaben-vm';
import { AufgabenViewService, DetailSourceReferenceType, SourceReference, SourceReferenceType } from '@onacta/onacta-vaufgaben-services';

const AufgabeMultiCreationPanel = lazy(() => import('../wrappers/AufgabeMultiCreationPanel'));
const AufgabeCreationDialog = lazy(() => import('../wrappers/AufgabeCreationDialog'));
const AufgabenListBySourceOverview = lazy(() => import('../wrappers/AufgabenListBySourceOverview'));

import { ActionComponent, AufgabenCreationComponentFactory, AufgabenOverviewComponentFactory, RefreshableUIComponent, UIComponent } from '@onacta/onacta-veakten-vm';

export class AufgabenCreationComponentFactoryImpl implements AufgabenCreationComponentFactory {
  private readonly aufgabenService: AufgabenViewService;
  
  constructor(s: ServiceRegistry ) {
    this.aufgabenService = s.create('@onacta/onacta-vaufgaben-services/AufgabenViewService');
  }
  
  public createComponent(aktenId: string): ActionComponent {
    const vm = new AufgabeMultiCreationVM(this.aufgabenService);
    return {
      getUI: () => {
        return <AufgabeMultiCreationPanel vm={vm} />;
      },
  
      execute: async (dokumentId?: string) => {
        const source: SourceReference = {
          id: '',
          refType: SourceReferenceType.AKTE,
          refId: aktenId,
          label: '',
        };
        if (dokumentId) {
          source.detailRefType = DetailSourceReferenceType.DOKUMENT;
          source.detailRefId = dokumentId;
        }
  
        await vm.create(source);
      }
    };
  }
  
  public createDialog(aktenId: string, dokumentId?: string, postCreate?: Consumer<string>): UIComponent {
    const source: SourceReference = {
      id: '',
      refType: SourceReferenceType.AKTE,
      refId: aktenId,
      label: '',
    };
    if (dokumentId) {
      source.detailRefType = DetailSourceReferenceType.DOKUMENT;
      source.detailRefId = dokumentId;
    }
    const vm = new AufgabeCreationVM(this.aufgabenService, source);
  
    return {
      getUI: () => {
        return <AufgabeCreationDialog vm={vm} postCreate={postCreate} />;
      }
    };
  }
}
  
export class AufgabenOverviewComponentFactoryImpl implements AufgabenOverviewComponentFactory {
  private readonly aufgabenService: AufgabenViewService;
  private readonly sourceRefType: SourceReferenceType;
  
  constructor(s: ServiceRegistry, sourceRefType: SourceReferenceType) {
    this.aufgabenService = s.create('@onacta/onacta-vaufgaben-services/AufgabenViewService');
    this.sourceRefType = sourceRefType;
  }
  
  public create(refId: string): RefreshableUIComponent {
    const vm = new AufgabenListBySourceOverviewVM(this.aufgabenService, { refType: this.sourceRefType, refId: refId });
  
    return {
      getUI: () => {
        return <AufgabenListBySourceOverview vm={vm} />;
      },
  
      refresh: () => {
        return vm.refresh();
      }
    };
  }
}