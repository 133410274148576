var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { INTERCEPTED_SERVICE_SUPPORT } from '@beso-rsd/beso-rsd-core';
export class EAktenViewServiceREST {
    constructor(baseUrl, client) {
        this.endpoint = baseUrl + '/eaktenviewservice';
        this.client = client;
    }
    listAkten(aktenzeichen = undefined, name = undefined) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('listAkten', this.client, `${this.endpoint}/akte`, { aktenzeichen, name }, {});
            return result;
        });
    }
    getAkteByAktenzeichen(aktenzeichen) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getAkteByAktenzeichen', this.client, `${this.endpoint}/aktenzeichen/${aktenzeichen}`, {}, {});
            return result + '';
        });
    }
    getDisplayName(aktenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getDisplayName', this.client, `${this.endpoint}/akte/${aktenId}/displayName`, {}, {});
            return result + '';
        });
    }
    getAktenteile(aktenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getAktenteile', this.client, `${this.endpoint}/akte/${aktenId}/aktenteile`, {}, {});
            return result;
        });
    }
    getAktenteil(aktenteilId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getAktenteil', this.client, `${this.endpoint}/aktenteil/${aktenteilId}`, {}, {});
            return result;
        });
    }
    createAktenteil(aktenId, name, paginiert) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('createAktenteil', this.client, `${this.endpoint}/aktenteil`, { aktenId, name, paginiert }, {});
            return result;
        });
    }
    deleteAktenteil(aktenteilId, etag) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.delete('deleteAktenteil', this.client, `${this.endpoint}/aktenteil/${aktenteilId}`, { etag }, {});
            return result;
        });
    }
    renameAktenteil(aktenteilId, etag, name) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.patch('renameAktenteil', this.client, `${this.endpoint}/aktenteil/${aktenteilId}/rename`, { etag, name }, {});
            return result;
        });
    }
    createBand(aktenteilId, name) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('createBand', this.client, `${this.endpoint}/band`, { aktenteilId, name }, {});
            return result;
        });
    }
    deleteBand(bandId, etag) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.delete('deleteBand', this.client, `${this.endpoint}/band/${bandId}`, { etag }, {});
            return result;
        });
    }
    getDokumentEditor(dokumentId, editable = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getDokumentEditor', this.client, `${this.endpoint}/dokument/${dokumentId}/editor`, { editable }, {});
            return result;
        });
    }
}
export class EAktenAnlageViewServiceREST {
    constructor(baseUrl, client) {
        this.endpoint = baseUrl + '/eaktenanlageviewservice';
        this.client = client;
    }
    getEbeneHauptgebiete() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getEbeneHauptgebiete', this.client, `${this.endpoint}/ebenen/hauptgebiet`, {}, {});
            return result;
        });
    }
    getEbeneGruppen(hauptgebietId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getEbeneGruppen', this.client, `${this.endpoint}/ebenen/hauptgebiet/${hauptgebietId}/gruppe`, {}, {});
            return result;
        });
    }
    getEbeneUntergruppen(gruppeId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getEbeneUntergruppen', this.client, `${this.endpoint}/ebenen/gruppe/${gruppeId}/untergruppe`, {}, {});
            return result;
        });
    }
    getEbeneEinzelakten(untergruppeId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getEbeneEinzelakten', this.client, `${this.endpoint}/ebenen/untergruppe/${untergruppeId}/einzelakte`, {}, {});
            return result;
        });
    }
    createAkteHauptgebiet(hauptgebietId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('createAkteHauptgebiet', this.client, `${this.endpoint}/create/hauptgebiet`, { hauptgebietId }, {});
            return result + '';
        });
    }
    createAkteGruppe(gruppeId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('createAkteGruppe', this.client, `${this.endpoint}/create/gruppe`, { gruppeId }, {});
            return result + '';
        });
    }
    createAkteUntergruppe(untergruppeId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('createAkteUntergruppe', this.client, `${this.endpoint}/create/untergruppe`, { untergruppeId }, {});
            return result + '';
        });
    }
    createAkteEinzelakte(einzelakteId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('createAkteEinzelakte', this.client, `${this.endpoint}/create/einzelakte`, { einzelakteId }, {});
            return result + '';
        });
    }
}
export class VeraktenViewServiceREST {
    constructor(baseUrl, client) {
        this.endpoint = baseUrl + '/veraktenviewservice';
        this.client = client;
    }
    getBaseData(aktenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getBaseData', this.client, `${this.endpoint}/base/${aktenId}`, {}, {});
            return result;
        });
    }
    verakten(daten, dokument) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('verakten', this.client, `${this.endpoint}/verakten/upload`, { daten, dokument }, {});
            return result + '';
        });
    }
    copyVerakten(daten, url) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('copyVerakten', this.client, `${this.endpoint}/verakten/copy`, { daten, url }, {});
            return result + '';
        });
    }
    preparedVerakten(daten, dokument) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('preparedVerakten', this.client, `${this.endpoint}/verakten/prepared`, { daten, dokument }, {});
            return result + '';
        });
    }
    prepareDocument(aktenId, dokument, ensurePDF = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('prepareDocument', this.client, `${this.endpoint}/prepare/upload`, { aktenId, dokument, ensurePDF }, {});
            return result;
        });
    }
    prepareCopy(aktenId, url, ensurePDF = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('prepareCopy', this.client, `${this.endpoint}/prepare/copy`, { aktenId, url, ensurePDF }, {});
            return result;
        });
    }
    prepareNachricht(aktenId, postfachId, nachrichtenId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.post('prepareNachricht', this.client, `${this.endpoint}/prepare/nachricht`, { aktenId, postfachId, nachrichtenId }, {});
            return result;
        });
    }
}
