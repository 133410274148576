import { Time, getLocalTimeZone, parseTime, startOfWeek } from '@internationalized/date';
import { leftPad, trim } from './string-utils';
import { State } from './base-apis';
export var DateFormats;
(function (DateFormats) {
    /**
     * Formats a given date to include day, month and year - eg 2010-01-05 will be formatted as "05.01.10" in case the locale is "de"
     */
    DateFormats[DateFormats["DATE_SHORT"] = 0] = "DATE_SHORT";
    /**
     * Formats a given date to include day, month and year - eg 2010-01-05 will be formatted as "05.01.2010" in case the locale is "de"
     */
    DateFormats[DateFormats["DATE_NORMAL"] = 1] = "DATE_NORMAL";
    /**
     * Formats a give date to include day and month-name (abbreviated) - eg 2010-03-05 will be formatted as "5. Mär." in case the locale is "de"
     */
    DateFormats[DateFormats["DATE_DAY_AND_MONTH"] = 2] = "DATE_DAY_AND_MONTH";
    /**
     * Formats a given date to include the full month-name and the year eg 2010-03-05 will be formatted as "Dezember 2020"
     */
    DateFormats[DateFormats["DATE_FULLMONTH_YEAR"] = 3] = "DATE_FULLMONTH_YEAR";
    /**
     * Formats a given date according to ISO 8601 (date only), this may be a no-op if the input is already in ISO format.
     * see https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates
     */
    DateFormats[DateFormats["DATE_ISO"] = 4] = "DATE_ISO";
    /**
     * Formats a given date-time to include day, month, year, hour, minute - eg 2010-01-05T10:00:00 will be formated as "05.01.10 10:00" in case the locale is "de"
     */
    DateFormats[DateFormats["DATETIME_SHORT"] = 5] = "DATETIME_SHORT";
    /**
     * Formats a given date-time to include day, month, year, hour, minute - eg 2010-01-05T10:00:00 will be formated as "5. Mär., 10:00" in case the locale is "de"
     */
    DateFormats[DateFormats["DATETIME_DAY_AND_MONTH"] = 6] = "DATETIME_DAY_AND_MONTH";
})(DateFormats || (DateFormats = {}));
const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = 60 * MILLIS_PER_SECOND;
const MILLIS_PER_HOUR = 60 * MILLIS_PER_MINUTE;
const MILLIS_PER_DAY = 24 * MILLIS_PER_HOUR;
let DATE_FORMATTER = () => 'NOT IMPLEMENTED';
export function setDateFormatter(dateSupport) {
    DATE_FORMATTER = dateSupport;
}
export function formatDate(date, format) {
    if (date === undefined) {
        return undefined;
    }
    return DATE_FORMATTER(date, format);
}
class YearWeekImpl {
    constructor(start, end) {
        this.start = start;
        this.end = end;
    }
    add(duration) {
        return getYearWeek(this.start.add(duration));
    }
    subtract(duration) {
        return getYearWeek(this.start.subtract(duration));
    }
    next() {
        return this.add({ weeks: 1 });
    }
    previous() {
        return this.subtract({ weeks: 1 });
    }
}
export function getYearMonth(date) {
    const month = date.month;
    const year = date.year;
    return { month, year };
}
export function getYearWeek(date) {
    const start = startOfWeek(date, 'de');
    const end = start.add({ days: 7 });
    return new YearWeekImpl(start, end);
}
export function isZonedDateTime(date) {
    return date.offset !== undefined;
}
export function isCalendarDateTime(date) {
    return !isZonedDateTime(date) && date.hour !== undefined;
}
export function isCalendarDate(date) {
    return !isCalendarDateTime(date);
}
export class Duration {
    constructor(milliSeconds) {
        this.milliSeconds = milliSeconds;
    }
    static between(start, end) {
        const a = start.toDate(getLocalTimeZone());
        const b = end.toDate(getLocalTimeZone());
        return new Duration(Date.UTC(b.getFullYear(), b.getMonth(), b.getDate()) - Date.UTC(a.getFullYear(), a.getMonth(), a.getDate()));
    }
    toDays() {
        return Math.floor(this.milliSeconds / MILLIS_PER_DAY);
    }
}
export function timeDifference(time, other) {
    const t1 = timeToMilliseconds(time);
    const t2 = timeToMilliseconds(other);
    if (t1 === t2) {
        return {};
    }
    else {
        let diff = t2 - t1;
        const hours = Math.floor(diff / MILLIS_PER_HOUR);
        diff %= MILLIS_PER_HOUR;
        const minutes = Math.floor(diff / MILLIS_PER_MINUTE);
        diff %= MILLIS_PER_MINUTE;
        const seconds = Math.floor(diff / MILLIS_PER_SECOND);
        const milliseconds = diff % MILLIS_PER_SECOND;
        return { hours, minutes, seconds, milliseconds };
    }
}
export function timeToMilliseconds(time) {
    return time.hour * MILLIS_PER_HOUR + time.minute * MILLIS_PER_MINUTE + time.second * MILLIS_PER_SECOND + time.millisecond;
}
export function tryParseTime(time = '', errorMessage) {
    if (trim(time) === '') {
        return { time: new Time(0, 0), state: State.OK, text: '' };
    }
    try {
        return { time: parseTime(trim(time)), state: State.OK, text: '' };
    }
    catch (e) {
        return { time: new Time(), state: State.ERROR, text: errorMessage };
    }
}
export function formatTime(time) {
    if (!time) {
        return '';
    }
    let rv = leftPad(time.hour, '0', 2);
    rv += ':' + leftPad(time.minute, '0', 2);
    if (time.second > 0 || time.millisecond > 0) {
        rv += ':' + leftPad(time.second, '0', 2);
        if (time.millisecond > 0) {
            rv += '.' + leftPad(time.second, '0', 3);
        }
    }
    return rv;
}
