import { useState } from 'react';

import {
  Button,
  ProgressCircle,
  TextField,
} from '@adobe/react-spectrum';

import { LoginService } from '@onacta/onacta-base-services';
import { SpectrumFlex } from '@baeso-ui/baeso-ui-spectrum';

export function LoginView(props : {success : () => void}) : JSX.Element {
  return <LoginViewPanel {...props} />;
}

const loginService = LoginService.getInstance();

export function LoginViewPanel( props : {success : () => void} ) : JSX.Element {
  const [ error, setErrorState ] = useState('');
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');

  const [ loginActive, setLoginActive ] = useState(false);
  const login = function login(success : () => void) {
        
    loginService.login(username, password).then( () => {
      setLoginActive(true);
      success();
    }).catch( err => {
      setLoginActive(false);
      if(err.status === 401){
        setErrorState('Username oder Passwort falsch');
      }else {
        setErrorState(err.error_description);
      }
    });
  };

  return <div className="login-screen" style={{ display: 'flex', margin: 'auto', width: '100%', height: '100%', backgroundImage: 'url(onacta-background.jpg)', backgroundSize: 'cover', verticalAlign: 'center' }}>
    <div className="login-layover" style={{ width: '100%', height: '100%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
      <SpectrumFlex UNSAFE_className="login-form" rowGap="size-125" direction="column" width="size-5000" paddingTop="size-250" paddingBottom="size-250" paddingStart="size-400" paddingEnd="size-400" boxShadow="0px 0px 5px rgb(96,238,254)" style={{ backgroundColor: 'rgba(5,26,50,0.96)', borderRadius: '10px' }}>
        <div style={{ color: 'white', fontSize: '30px', fontWeight: 'bold', marginBottom: '20px', textAlign: 'center' }}>OnActa Anmeldung</div>
        <SpectrumFlex direction="column" rowGap="size-250">
          <TextField id= "UserName" autoFocus placeholder="Benutzername" aria-label="Benutzername" value={username} onChange={setUsername} width="100%" />
          <TextField id= "Password" placeholder="Passwort" aria-label="Passwort" value={password} onChange={setPassword} type='password' width="100%" />
        </SpectrumFlex>
        <div style={{ color: 'red' }}>
          {error}
        </div>
        <SpectrumFlex alignContent="end" alignItems="center" columnGap="size-125">
          <ProgressCircle aria-label="Login…" isIndeterminate UNSAFE_style={{ visibility: (loginActive ? 'visible': 'hidden') }} />
          <Button variant="overBackground" marginStart="auto">Zurücksetzen</Button>
          <Button id="login" variant="cta" onPress={ () => login(props.success)}>Login</Button>
        </SpectrumFlex>
      </SpectrumFlex>
    </div>
  </div>;
}