export var Position;
(function (Position) {
    Position[Position["NACH"] = 0] = "NACH";
    Position[Position["VOR"] = 1] = "VOR";
    Position[Position["UNTER"] = 2] = "UNTER";
    Position[Position["VERBINDEN"] = 3] = "VERBINDEN";
    Position[Position["UNTER_VERBINDEN"] = 4] = "UNTER_VERBINDEN";
})(Position || (Position = {}));
export function extractVeraktung(obj) {
    if (obj.veraktungPaginiert) {
        return obj.veraktungPaginiert;
    }
    if (obj.veraktungUnpaginiert) {
        return obj.veraktungUnpaginiert;
    }
    throw new Error('Invalid state');
}
export function extractDokumentEditorConfig(obj) {
    if (obj.pdfViewer) {
        return obj.pdfViewer;
    }
    if (obj.onlyOfficeEditorConfig) {
        return obj.onlyOfficeEditorConfig;
    }
    throw new Error('Invalid state');
}
export function isVeraktungPaginiert(obj) {
    return obj['@type'] === 'VeraktungPaginiert';
}
export function isVeraktungUnpaginiert(obj) {
    return obj['@type'] === 'VeraktungUnpaginiert';
}
export function isPdfViewer(obj) {
    return obj['@type'] === 'PdfViewer';
}
export function isOnlyOfficeEditorConfig(obj) {
    return obj['@type'] === 'OnlyOfficeEditorConfig';
}
