import { Suspense, lazy, useState } from 'react';

import { Route, Routes, generatePath, useNavigate } from 'react-router-dom';

import data from '../../package.json';

import moment from 'moment';

import 'moment/locale/de';


import { AlertDialog, DialogContainer, Provider, darkTheme, defaultTheme, lightTheme, } from '@adobe/react-spectrum';
import { Theme } from '@react-types/provider';

import { Action, SpectrumFlex, useDateFormatter, useTypedMessageFormatter, useValue } from '@baeso-ui/baeso-ui-spectrum';
import { ServiceRegistry, isElectron, setDateFormatter } from '@baeso-ui/baeso-core';
import { AxiosRestClient } from '@beso-rsd/beso-rsd-axios';
import { ServiceContext } from '@baeso-ui/baeso-ui-spectrum';
import { SpectrumWorkspace as Workspace } from '@baeso-ui/baeso-ui-spectrum';


import { ScaleType, ThemeType, VisionDeficiencyEmulation, preferenceStore } from '@onacta/onacta-base-ui';

import { FileServiceREST, PDFDocumentServiceREST } from '@baeso-pdfviewer/pdfviewer-services';

import { DashboardViewServiceREST } from '@onacta/onacta-vdashboard-services';
const HomeView = lazy(() => import('@onacta/onacta-vdashboard-spectrum'));

import { AkteLinkOptions, createAktenLink as eakteCreateLink } from '@onacta/onacta-veakten-spectrum/build/index-routes'; // FIXME determine how to split the package properly with subpath exports, so code splitting continues to work (avoid static '@onacta/onacta-veakten-spectrum' import)
import { EAktenAnlageViewServiceREST, EAktenViewServiceREST, VeraktenViewServiceREST } from '@onacta/onacta-veakten-services';
const EAktenView = lazy(() => import('@onacta/onacta-veakten-spectrum'));

import { NachrichtenViewServiceREST } from '@onacta/onacta-vnachrichten-services';
const NachrichtenView = lazy(() => import('@onacta/onacta-vnachrichten-spectrum'));

import { SourceReferenceType as AufgabenSourceReferenceType, AufgabenViewServiceREST } from '@onacta/onacta-vaufgaben-services';
const AufgabenView = lazy(() => import('@onacta/onacta-vaufgaben-spectrum'));

import { AufgabenCreationComponentFactoryImpl, AufgabenOverviewComponentFactoryImpl } from './adapters/Aufgaben';
import { VeraktungsComponentFactoryImpl } from './adapters/EAkten';

import { LoginPage, RequireAuth } from './Auth';
import { Messages, messages } from './Messages';
import { EinstellungsDialog } from './EinstellungsDialog';
import { LoadingScreen } from './LoadingScreen';

import logo from '../img/logo_128.png';


const BASE_URL = isElectron() ? process.env.REACT_APP_SERVICE_ELECTRON_BASEURL : '';
const s = new ServiceRegistry();

moment.locale('de');

if( process.env.REACT_APP_SERVICE_TYPE === 'mock' ) {
  console.log('We are in mock ...');
  /* eslint-disable @typescript-eslint/no-var-requires */
  const nachrichten = require('@onacta/onacta-vnachrichten-services-mock');
  const dashboard = require('@onacta/onacta-vdashboard-services-mock');
  const eakten = require('@onacta/onacta-veakten-services-mock');
  const aufgaben = require('@onacta/onacta-vaufgaben-services-mock');
  /* eslint-enable @typescript-eslint/no-var-requires */
  
  s.register('@onacta/onacta-vnachrichten-services/NachrichtenViewService', () => new nachrichten.MockNachrichtenViewService() );
  s.register('@onacta/onacta-vdashboard-services/DashboardViewService', () => new dashboard.MockDashboardViewService());
  s.register('@onacta/onacta-veakten-services/EAktenViewService', () => new eakten.MockEAktenViewService());
  s.register('@onacta/onacta-veakten-services/EAktenAnlageViewService', () => new eakten.MockEAktenAnlageViewService());
  s.register('@onacta/onacta-veakten-services/VeraktenViewService', () => new eakten.MockVeraktenViewService());
  s.register('@baeso-pdfviewer/pdfviewer-services/PDFDocumentService', () => new eakten.MockPDFDocumentService());
  s.register('@baeso-pdfviewer/pdfviewer-services/FileService', () => new eakten.MockPDFFileService());
  s.register('@onacta/onacta-vaufgaben-services/AufgabenViewService', () => new aufgaben.MockAufgabenViewService());
} else {
  s.register('@onacta/onacta-vnachrichten-services/NachrichtenViewService', () => new NachrichtenViewServiceREST(BASE_URL+'/api/v1/nachrichten', new AxiosRestClient()) );
  s.register('@onacta/onacta-vdashboard-services/DashboardViewService', () => new DashboardViewServiceREST(BASE_URL+'/api/v1/dashboard', new AxiosRestClient()) );
  s.register('@onacta/onacta-veakten-services/EAktenViewService', () => new EAktenViewServiceREST(BASE_URL+'/api/v1/eakten', new AxiosRestClient()));
  s.register('@onacta/onacta-veakten-services/EAktenAnlageViewService', () => new EAktenAnlageViewServiceREST(BASE_URL+'/api/v1/eakten', new AxiosRestClient()));
  s.register('@onacta/onacta-veakten-services/VeraktenViewService', () => new VeraktenViewServiceREST(BASE_URL+'/api/v1/eakten', new AxiosRestClient()));
  s.register('@baeso-pdfviewer/pdfviewer-services/PDFDocumentService', () => new PDFDocumentServiceREST(BASE_URL + '/api/v1/pdf', new AxiosRestClient()));
  s.register('@baeso-pdfviewer/pdfviewer-services/FileService', () => new FileServiceREST(BASE_URL + '/api/v1/pdf', new AxiosRestClient()));
  s.register('@onacta/onacta-vaufgaben-services/AufgabenViewService', () => new AufgabenViewServiceREST(BASE_URL+'/api/v1/aufgaben', new AxiosRestClient()));
}

interface ColorTheme {
  key: ThemeType,
  theme: Theme,
  colorScheme: 'light' | 'dark' | undefined
}

interface Scale {
  key: ScaleType,
  scale: 'medium' | 'large' | undefined
}

export const COLOR_THEMES : ColorTheme[] = [
  { key: 'standard', theme: defaultTheme, colorScheme: undefined },
  { key: 'light', theme: defaultTheme, colorScheme: 'light' },
  { key: 'extra-light', theme: lightTheme, colorScheme: 'light' },
  { key: 'dark', theme: darkTheme, colorScheme: 'light' },
  { key: 'extra-dark', theme: darkTheme, colorScheme: 'dark' },
];

export const SCALES : Scale[] = [
  { key: 'standard', scale: undefined },
  { key: 'normal', scale: 'medium' },
  { key: 'large', scale: 'large' },
];

function toTheme(theme?: ThemeType) {
  return COLOR_THEMES.find( t => t.key == theme ) ?? COLOR_THEMES[0];
}

function toScale(scale?: ScaleType) {
  return SCALES.find( s => s.key == scale ) ?? SCALES[0];
}

export function OnActa() : JSX.Element {
  const [ theme ] = useValue(preferenceStore.theme);
  const [ scale ] = useValue(preferenceStore.scale);

  const spectrumTheme = toTheme(theme);
  const spectrumScale = toScale(scale);

  const dateFormatter = useDateFormatter();
  setDateFormatter( dateFormatter );

  return <Provider theme={spectrumTheme?.theme || defaultTheme} colorScheme={spectrumTheme?.colorScheme} scale={spectrumScale?.scale} flexGrow={1} UNSAFE_style={{ display: 'flex', minWidth: 0 }}>
    <ServiceContext.Provider value={s}>
      <VisionDeficiencyEmulation>
        <Routes>
          <Route path="/*" element={<RequireAuth />}>
            <Route path="*" element={<HauptFenster />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </VisionDeficiencyEmulation>
    </ServiceContext.Provider>
  </Provider>;
}

const ROUTE_AKTE = 'akte/*';
function createAktenLink(opts?: AkteLinkOptions): string {
  return '/' + generatePath(ROUTE_AKTE, { '*': opts ? eakteCreateLink(opts) : '' });
}

function HauptFenster() {
  const m = useTypedMessageFormatter<Messages>(messages);
  const navigate = useNavigate();
  const [ isShowAbout, setShowAbout ] = useState(false);
  const [ isShowPrefs, setShowPrefs ] = useState(false);

  const aufgabenCreationComponentFactory = new AufgabenCreationComponentFactoryImpl(s);
  const veraktungsComponentFactory = new VeraktungsComponentFactoryImpl(s, aufgabenCreationComponentFactory);
  const aufgabenOverviewComponentFactory = new AufgabenOverviewComponentFactoryImpl(s, AufgabenSourceReferenceType.AKTE);

  const componentActions : Action[] = [
    {
      key: 'main-page',
      icon: 'home',
      label: m('onacta-main.mainpage'),
      onAction: () => navigate('./'),
    },
    {
      key: 'search-page',
      icon: 'fa-file-search',
      label: m('onacta-main.search-page'),
      onAction: () => { console.log('Open Search page'); }
    },
    {
      key: 'tasks-page',
      icon: 'fa-tasks',
      label: m('onacta-main.tasks'),
      onAction: () => navigate('./aufgaben'),
    },
    {
      key: 'mail-page',
      icon: 'fa-mail-bulk',
      label: m('onacta-main.mailbox'),
      onAction: () => navigate('./nachrichten'),
    },
    {
      key: 'eakte-page',
      icon: 'archive',
      label: m('onacta-main.file'),
      onAction: () => navigate(createAktenLink()),
    },
  ];

  const actions : Action[] = [
    {
      key: 'app-prefs',
      icon: 'pref-icon',
      label: m('onacta-main.global-preferences'),
      onAction: () => { setShowPrefs(true); }
    },
    {
      key: 'app-info',
      icon: 'about-icon',
      label: m('onacta-main.app-info'),
      onAction: () => { setShowAbout(true); }
    },
  ];

  return <>
    <Workspace actions={actions} componentActions={componentActions} title="OnActa">
      <Suspense fallback={<LoadingScreen message='Lade Komponente' />}>
        <Routes>
          <Route index element={<HomeView serviceRegistry={s} />} />
          <Route path="aufgaben/*" element={<AufgabenView serviceRegistry={s} createAktenLink={createAktenLink} />} />
          <Route path="nachrichten/*" element={<NachrichtenView veraktungsCompFactory={veraktungsComponentFactory} onClose={() => navigate('./')} createAktenLink={createAktenLink} serviceRegistry={s} />} />
          <Route path={ROUTE_AKTE} element={<EAktenView serviceRegistry={s} aufgabenCreationComponentFactory={aufgabenCreationComponentFactory} aufgabenOverviewComponentFactory={aufgabenOverviewComponentFactory} />} />
        </Routes>
      </Suspense>
    </Workspace>
    <DialogContainer onDismiss={ () => setShowAbout(false)}>
      { isShowAbout && <AlertDialog width={600} autoFocusButton="primary" variant="information" primaryActionLabel="OK" title="OnActa - Die Online Akte">
        <SpectrumFlex gap={20} marginStart={10}>
          <img alt="OnActa Logo" src={logo} width={128} height={128} style={{ alignSelf: 'start' }} />
          <div>
            <strong>Version:</strong><br /> {data.version}<br />
            <strong>Build:</strong><br /> {process.env.REACT_APP_BUILD_TIMESTAMP}<br />
            <strong>Hash:</strong><br /> {process.env.REACT_APP_BUILD_HASH}<br />
          </div>
        </SpectrumFlex>
      </AlertDialog> }
    </DialogContainer>
    <DialogContainer onDismiss={ () => setShowPrefs(false) }>
      { isShowPrefs && <EinstellungsDialog dismissHandler={ () => setShowPrefs(false) } /> }
    </DialogContainer>
  </>;
}