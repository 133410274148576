import { lazy } from 'react';

import { Consumer, ServiceRegistry } from '@baeso-ui/baeso-core';

import { AufgabenCreationComponentFactory, NachrichtVeraktungsVM } from '@onacta/onacta-veakten-vm';
import { PDFDocumentService } from '@baeso-pdfviewer/pdfviewer-services';
import { VeraktenViewService } from '@onacta/onacta-veakten-services';

import { UIComponent, VeraktungsComponentFactory } from '@onacta/onacta-vnachrichten-vm';
const NachrichtVeraktungsDialog = lazy(() => import('../wrappers/NachrichtVeraktungsDialog'));

export class VeraktungsComponentFactoryImpl implements VeraktungsComponentFactory {
  private readonly veraktenService: VeraktenViewService;
  private readonly pdfService: PDFDocumentService;
  private readonly aufgabenCreationComponentFactory: AufgabenCreationComponentFactory;
  
  constructor(s: ServiceRegistry, aufgabenCreationComponentFactory: AufgabenCreationComponentFactory ) {
    this.veraktenService = s.create<VeraktenViewService>('@onacta/onacta-veakten-services/VeraktenViewService');
    this.pdfService = s.create<PDFDocumentService>('@baeso-pdfviewer/pdfviewer-services/PDFDocumentService');
    this.aufgabenCreationComponentFactory = aufgabenCreationComponentFactory;
  }
  
  public createDialog(postfachId: string, nachrichtenId: string, aktenId: string, dokumentName: string | null, postCreate?: Consumer<string>): UIComponent {
      
    const vm = new NachrichtVeraktungsVM(this.veraktenService, this.pdfService, this.aufgabenCreationComponentFactory.createComponent(aktenId), aktenId, postfachId, nachrichtenId, dokumentName ?? undefined);

    return {
      getUI: () => {
        return <NachrichtVeraktungsDialog vm={vm} postCreate={postCreate} />;
      }
    };
  }
}
