import { BrowserRouter } from 'react-router-dom';

import { OnActa } from './onacta/OnActa';

function App() : JSX.Element {
  return <BrowserRouter>
    <OnActa />
  </BrowserRouter>;
}

export default App;
