var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { INTERCEPTED_SERVICE_SUPPORT } from '@beso-rsd/beso-rsd-core';
export class DashboardViewServiceREST {
    constructor(baseUrl, client) {
        this.endpoint = baseUrl + '/dashboardviewservice';
        this.client = client;
    }
    getNachrichtenStatistik(letzterTag) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getNachrichtenStatistik', this.client, `${this.endpoint}/nachrichten/stats`, { letzterTag }, {});
            return result;
        });
    }
    getUngeleseneNachrichten() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getUngeleseneNachrichten', this.client, `${this.endpoint}/nachrichten`, {}, {});
            return result;
        });
    }
    getUpcomingTasks() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield INTERCEPTED_SERVICE_SUPPORT.get('getUpcomingTasks', this.client, `${this.endpoint}/tasks`, {}, {});
            return result;
        });
    }
}
