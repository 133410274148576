import { Heading } from '@adobe/react-spectrum';

import { SpectrumFlex } from '@baeso-ui/baeso-ui-spectrum';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale } from '@fortawesome/pro-regular-svg-icons';

export function LoadingScreen(props: { message: string }): JSX.Element {
  return <SpectrumFlex direction="column" alignItems="center" justifyContent="center" width="100%" UNSAFE_className='onacta-loading'>
    <FontAwesomeIcon icon={faBalanceScale} size="6x" />
    <Heading level={1}>{props.message} ...</Heading>
  </SpectrumFlex>;
}