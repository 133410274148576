export class VMItem {
    constructor(id, label, children) {
        this.id = id;
        this.label = label;
        this.children = children;
    }
}
export function toVMItems(dto) {
    return dto.map(v => {
        return {
            id: v.id,
            label: v.label,
        };
    });
}
export function toListItems(dtos, allChildren) {
    return dtos.map(dto => {
        return {
            key: dto.id,
            text: dto.label,
            children: allChildren !== undefined ? allChildren.filter(child => child.parentID === dto.id).map(child => {
                return {
                    key: child.id,
                    text: child.label
                };
            }) : undefined
        };
    });
}
