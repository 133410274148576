import { Button, ButtonGroup, Content, Dialog, Divider, Heading, Switch, } from '@adobe/react-spectrum';

import { DropdownOption, SpectrumDropDown, TypedFormatMessage, useTypedMessageFormatter, useValue } from '@baeso-ui/baeso-ui-spectrum';

import { ScaleType, ThemeType, VISION_DEFICIENCIES, VisionDeficiencyType, preferenceStore } from '@onacta/onacta-base-ui';

import { COLOR_THEMES, SCALES } from './OnActa';
import { Messages, messages } from './Messages';

export type foo = Extract<keyof Messages, string>;

function colorThemeToOption(item: ThemeType, m: TypedFormatMessage<Messages>): DropdownOption {
  const messageKey = ((): keyof Messages => {
    switch(item) {
    case 'standard': return 'onacta-main.preferences.color-theme.standard';
    case 'dark': return 'onacta-main.preferences.color-theme.dark';
    case 'extra-dark': return 'onacta-main.preferences.color-theme.extra-dark';
    case 'light': return 'onacta-main.preferences.color-theme.light';
    case 'extra-light': return 'onacta-main.preferences.color-theme.extra-light';
    default: return item; // serves as exhaustiveness check for the switch case, fails compilation of item is not mapped
    }
  })();
  return {
    key: item,
    text: m(messageKey)
  };
}

function scaleToOption(item: ScaleType, m: TypedFormatMessage<Messages>): DropdownOption {
  const messageKey = ((): keyof Messages => {
    switch(item) {
    case 'standard': return 'onacta-main.preferences.scale.standard';
    case 'normal': return 'onacta-main.preferences.scale.normal';
    case 'large': return 'onacta-main.preferences.scale.large';
    default: return item; // serves as exhaustiveness check for the switch case, fails compilation of item is not mapped
    }
  })();
  return {
    key: item,
    text: m(messageKey)
  };
}

function visionToOption(item: VisionDeficiencyType, m: TypedFormatMessage<Messages>): DropdownOption {
  const messageKey = ((): keyof Messages => {
    switch(item) {
    case 'off': return 'onacta-main.preferences.visiondef-emulation.off';
    case 'mono': return 'onacta-main.preferences.visiondef-emulation.mono';
    case 'blurred-vision': return 'onacta-main.preferences.visiondef-emulation.blurred-vision';
    case 'protanopia': return 'onacta-main.preferences.visiondef-emulation.protanopia';
    case 'deuteranopia': return 'onacta-main.preferences.visiondef-emulation.deuteranopia';
    case 'protanomaly': return 'onacta-main.preferences.visiondef-emulation.protanomaly';
    case 'deuteranomaly': return 'onacta-main.preferences.visiondef-emulation.deuteranomaly';
    case 'tritanopia': return 'onacta-main.preferences.visiondef-emulation.tritanopia';
    case 'tritanomaly': return 'onacta-main.preferences.visiondef-emulation.tritanomaly';
    case 'achromatopsia': return 'onacta-main.preferences.visiondef-emulation.achromatopsia';
    case 'achromatomaly': return 'onacta-main.preferences.visiondef-emulation.achromatomaly';
    default: return item; // serves as exhaustiveness check for the switch case, fails compilation of item is not mapped
    }
  })();
  return {
    key: item,
    text: m(messageKey)
  };
}

export function EinstellungsDialog(props: { dismissHandler: () => void }) {
  const m = useTypedMessageFormatter<Messages>(messages);
  const [ darkModeDocument, setDarkModeDocument ] = useValue(preferenceStore.darkModeDocument);
  return <Dialog>
    <Heading>{m('onacta-main.global-preferences')}</Heading>
    <Divider />
    <Content>
      <SpectrumDropDown label={m('onacta-main.preferences.color-theme')} items={COLOR_THEMES.map(t => t.key)} itemToOption={item => colorThemeToOption(item, m)} value={preferenceStore.theme} placeholder={m('onacta-main.preferences.color-theme.placeholder')} />
      <SpectrumDropDown label={m('onacta-main.preferences.scale')} items={SCALES.map(s => s.key)} itemToOption={item => scaleToOption(item, m)} value={preferenceStore.scale} placeholder={m('onacta-main.preferences.scale.placeholder')} />
      <SpectrumDropDown label={m('onacta-main.preferences.visiondef-emulation')} items={VISION_DEFICIENCIES} itemToOption={item => visionToOption(item, m)} value={preferenceStore.visionDeficiencyEmulation} placeholder={m('onacta-main.preferences.visiondef-emulation.placeholder')} />
      <Switch isSelected={darkModeDocument} onChange={setDarkModeDocument}>{m('onacta-main.preferences.darkModeDocument')}</Switch>
    </Content>
    <ButtonGroup>
      <Button variant="cta" onPress={props.dismissHandler}>{m('onacta-main.close')}</Button>
    </ButtonGroup>
  </Dialog>;
}
