var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Binding, BooleanProperty, ObjectProperty, ObservableArray, Property } from '@baeso-ui/baeso-observable';
import { OK_MESSAGE, State } from '@baeso-ui/baeso-core';
import { UploadVeraktungsVM } from './VeraktungsDialog.vm';
export var ViewerPanel;
(function (ViewerPanel) {
    ViewerPanel[ViewerPanel["VERAKTETE_DOKUMENTE"] = 0] = "VERAKTETE_DOKUMENTE";
    ViewerPanel[ViewerPanel["NEUE_DOKUMENTE"] = 1] = "NEUE_DOKUMENTE";
})(ViewerPanel || (ViewerPanel = {}));
export class EAktenViewPanelVM {
    constructor(eaktenService, veraktenService, pdfDokumentService, aktenId, aufgabenCreationComponentFactory, aufgabenOverviewComponentFactory) {
        this.aktuellerPaginierterAktenteil = new ObjectProperty('aktuellerPaginierterAktenteil');
        this.aktuellerUnpaginierterAktenteil = new ObjectProperty('aktuellerPaginierterAktenteil');
        this.title = new Property('title', '-');
        this.veraktungsDialogVM = new ObjectProperty('veraktungsDialogVM', undefined);
        this.showPaginationPanels = new BooleanProperty('showPaginationPanels');
        this.dataLoaded = new BooleanProperty('dataLoaded');
        this.eaktenService = eaktenService;
        this.veraktenService = veraktenService;
        this.pdfDokumentService = pdfDokumentService;
        this.aktenId = aktenId;
        this.aufgabenCreationComponentFactory = aufgabenCreationComponentFactory;
        this.toolSidebarVM = new AktenToolSidebarVM(aktenId, aufgabenOverviewComponentFactory);
        this.aktenteilePanel = new AktenteilePanelVM(this.eaktenService, this.aktenId);
        this.paginierterAktenteilPanel = new PaginierterAktenteilPanelVM(this.eaktenService, this.pdfDokumentService, this.aktenId, aufgabenCreationComponentFactory, () => this.toolSidebarVM.refreshAufgaben(), this.aktuellerPaginierterAktenteil);
        this.unpaginierterAktenteilPanel = new UnpaginierterAktenteilPanelVM(this.eaktenService, this.pdfDokumentService, this.aktuellerUnpaginierterAktenteil);
        this.aktenDisplayName = null;
        eaktenService.getDisplayName(aktenId).then(displayName => {
            this.aktenDisplayName = displayName;
            this.updateTitle();
        });
        this.aktenteilePanel.selectedAktenteil.onValueChange((ne) => {
            this.updateTitle();
            if (ne) {
                if (ne.dto.paginiert) {
                    this.aktuellerPaginierterAktenteil.value = ne;
                    this.showPaginationPanels.value = true;
                }
                else {
                    this.aktuellerUnpaginierterAktenteil.value = ne;
                    this.showPaginationPanels.value = false;
                }
                this.dataLoaded.value = true;
            }
            else {
                this.dataLoaded.value = false;
            }
        });
    }
    updateTitle() {
        const aktenteil = this.aktenteilePanel.selectedAktenteil.value;
        if (!this.aktenDisplayName && !aktenteil) {
            this.title.value = '-';
        }
        else {
            this.title.value = (this.aktenDisplayName ? this.aktenDisplayName : '-')
                + ' | '
                + (aktenteil ? aktenteil.name.value : '-');
        }
    }
    openVeraktungsDialog() {
        var _a, _b;
        const bandId = this.showPaginationPanels.value
            ? (_a = this.paginierterAktenteilPanel.selectedBand.value) === null || _a === void 0 ? void 0 : _a.dto.id
            : (_b = this.unpaginierterAktenteilPanel.selectedBand.value) === null || _b === void 0 ? void 0 : _b.dto.id;
        const aktenteil = this.aktenteilePanel.selectedAktenteil.value;
        if (!aktenteil || !bandId) {
            throw new Error('no Aktenteil/Band selected');
        }
        this.veraktungsDialogVM.value = new UploadVeraktungsVM(this.veraktenService, this.pdfDokumentService, this.aufgabenCreationComponentFactory.createComponent(this.aktenId), this.aktenId, aktenteil.dto.id, bandId);
    }
    closeVeraktungsDialog(newDokumentId) {
        this.veraktungsDialogVM.value = undefined;
        if (newDokumentId !== undefined) {
            this.paginierterAktenteilPanel.paginierterBandVM.verakteteDokumentePanel.reloadData({ dokumentId: newDokumentId, seite: 0 });
            this.unpaginierterAktenteilPanel.bandVM.dokumentePanel.reloadData();
            this.toolSidebarVM.refreshAufgaben();
        }
    }
}
export class AktenteilePanelVM {
    constructor(eaktenService, aktenId) {
        this.visible = new Property('visible', false);
        this.selectedAktenteil = new Property('selectedAktenteil', undefined);
        this.aktenteile = new Property('aktenteile', []);
        this.eaktenService = eaktenService;
        this.aktenId = aktenId;
        this.loadData();
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            const data = (yield this.eaktenService.getAktenteile(this.aktenId)).map(dto => new AktenteilItem(dto, this.eaktenService));
            this.aktenteile.value = data;
            this.selectedAktenteil.value = data[0];
        });
    }
    addAktenteil(name, paginiert) {
        return __awaiter(this, void 0, void 0, function* () {
            if (name === undefined) {
                return Promise.reject('Named is not defined');
            }
            const result = yield this.eaktenService.createAktenteil(this.aktenId, name, paginiert);
            this.aktenteile.value = [...this.aktenteile.value, new AktenteilItem(result, this.eaktenService)];
        });
    }
    removeAktenteil(item) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.eaktenService.deleteAktenteil(item.dto.id, item.dto.etag);
            this.aktenteile.value = this.aktenteile.value.filter(a => a !== item);
        });
    }
    validateAktenteilname(name) {
        if (!name || name.trim().length === 0) {
            return { state: State.ERROR, text: 'Name darf nicht leer sein' };
        }
        else if (this.aktenteile.value.find(e => e.name.value === name)) {
            return { state: State.ERROR, text: 'Aktenteil mit dem Namen "' + name + '" existiert bereits' };
        }
        return OK_MESSAGE;
    }
}
export class AktenToolSidebarVM {
    constructor(aktenId, aufgabenOverviewComponentFactory) {
        this.aufgabenOverviewComponent = aufgabenOverviewComponentFactory.create(aktenId);
    }
    refreshAufgaben() {
        return this.aufgabenOverviewComponent.refresh();
    }
}
export class AktenteilItem {
    constructor(dto, eaktenService) {
        this.dto = dto;
        this.name = new Property('name', dto.name);
        this.eaktenService = eaktenService;
    }
    rename(name) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.eaktenService.renameAktenteil(this.dto.id, this.dto.etag, name);
            this.name.value = name;
        });
    }
    refresh() {
        return __awaiter(this, void 0, void 0, function* () {
            this.dto = yield this.eaktenService.getAktenteil(this.dto.id);
            this.name.value = this.dto.name;
        });
    }
}
export class BandItem {
    constructor(aktenteil, dto) {
        this.aktenteil = aktenteil;
        this.dto = dto;
        this.name = new Property('name', dto.name);
    }
    updateFromDTO() {
        this.name.value = this.dto.name;
    }
    refresh() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.aktenteil.refresh();
            const band = this.aktenteil.dto.baende.find(b => b.id == this.dto.id);
            if (band === undefined) {
                throw Error('failed to find Band in Aktenteil during refresh');
            }
            else {
                this.dto = band;
                this.updateFromDTO();
            }
        });
    }
}
var AktenteilPanelVMValidationStatus;
(function (AktenteilPanelVMValidationStatus) {
    AktenteilPanelVMValidationStatus[AktenteilPanelVMValidationStatus["OK"] = 0] = "OK";
    AktenteilPanelVMValidationStatus[AktenteilPanelVMValidationStatus["NAME_EMTPY"] = 1] = "NAME_EMTPY";
    AktenteilPanelVMValidationStatus[AktenteilPanelVMValidationStatus["NAME_EXISTS"] = 2] = "NAME_EXISTS";
})(AktenteilPanelVMValidationStatus || (AktenteilPanelVMValidationStatus = {}));
export class AktenteilPanelVM {
    constructor(eaktenService, selectedAktenteil) {
        this.eaktenService = eaktenService;
        this.selectedAktenteil = selectedAktenteil;
        this.selectedBand = new ObjectProperty('selectedBand');
        this.baende = new ObservableArray('baende', []);
        this.selectedAktenteil.onValueChange(() => this.loadBaende());
        this.loadBaende();
    }
    loadBaende() {
        const baende = this.baende;
        if (this.selectedAktenteil.value !== undefined) {
            const aktenteil = this.selectedAktenteil.value;
            baende.setAll(aktenteil.dto.baende.map(b => new BandItem(aktenteil, b)));
        }
        else {
            baende.clear();
        }
        this.selectedBand.value = baende.length > 0 ? baende.value[0] : undefined;
    }
    addBand(name) {
        return __awaiter(this, void 0, void 0, function* () {
            if (name === undefined) {
                return Promise.reject('Named is not defined');
            }
            const aktenteil = this.selectedAktenteil.value;
            if (aktenteil === undefined) {
                return Promise.reject('No Aktenteil selected');
            }
            const result = yield this.eaktenService.createBand(aktenteil.dto.id, name);
            this.baende.push(new BandItem(aktenteil, result));
        });
    }
    removeBand(item) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.eaktenService.deleteBand(item.dto.id, item.dto.etag);
            this.baende.remove(item);
            if (item == this.selectedBand.value) {
                this.selectedBand.value = this.baende.value.length > 0 ? this.baende.value[0] : undefined;
            }
        });
    }
    validateBandName(name) {
        if (!name || name.trim().length === 0) {
            return AktenteilPanelVM.ValidationStatus.NAME_EMTPY;
        }
        else if (this.baende.value.find(e => e.name.value === name)) {
            return AktenteilPanelVM.ValidationStatus.NAME_EXISTS;
        }
        return AktenteilPanelVM.ValidationStatus.OK;
    }
}
AktenteilPanelVM.ValidationStatus = AktenteilPanelVMValidationStatus;
export class PaginierterAktenteilPanelVM extends AktenteilPanelVM {
    constructor(eaktenService, pdfDokumentService, aktenId, aufgabenCreationComponentFactory, aufgabenChanged, selectedAktenteil) {
        super(eaktenService, selectedAktenteil);
        this.paginierterBandVM = new PaginierterBandPanelVM(pdfDokumentService, aktenId, aufgabenCreationComponentFactory, aufgabenChanged, this.selectedBand);
    }
}
export class UnpaginierterAktenteilPanelVM extends AktenteilPanelVM {
    constructor(eaktenService, pdfDokumentService, selectedAktenteil) {
        super(eaktenService, selectedAktenteil);
        this.bandVM = new UnpaginierterBandPanelVM(eaktenService, pdfDokumentService, this.selectedBand);
    }
}
export class PaginierterBandPanelVM {
    constructor(pdfDokumentService, aktenId, aufgabenCreationComponentFactory, aufgabenChanged, selectedBand) {
        this.aktiverViewer = new Property('aktiverView', ViewerPanel.VERAKTETE_DOKUMENTE);
        this.pdfDokumentService = pdfDokumentService;
        this.verakteteDokumentePanel = new PaginiertVerakteteDokumentPanelVM(this.pdfDokumentService, aktenId, aufgabenCreationComponentFactory, aufgabenChanged, selectedBand);
        this.verakteteDokumentViewerPanel = new PaginiertVerakteteDokumentViewerPanelVM(pdfDokumentService, this.verakteteDokumentePanel.aktuelleSeite, this.verakteteDokumentePanel.pdfDokumente, this.aktiverViewer);
    }
}
export class PaginiertVerakteteDokumentPanelVM {
    constructor(pdfDokumentService, aktenId, aufgabenCreationComponentFactory, aufgabenChanged, selectedBand) {
        this.openDialog = new ObjectProperty('openDialog');
        this.pdfDokumente = new Property('pdfDokumente', []);
        this.aktuelleSeite = new Property('aktuelleSeite', undefined);
        this.aktiv = new Property('aktiv', false);
        this.pdfDokumentService = pdfDokumentService;
        this.aktenId = aktenId;
        this.aufgabenCreationComponentFactory = aufgabenCreationComponentFactory;
        this.aufgabenChanged = aufgabenChanged;
        this.selectedBand = selectedBand;
        this.selectedBand.onValueChange(v => this.loadData(v));
    }
    loadData(band, aktuelleSeite) {
        return __awaiter(this, void 0, void 0, function* () {
            if (band == undefined) {
                this.pdfDokumente.value = [];
            }
            else {
                const pdfDokumentService = this.pdfDokumentService;
                const allPromises = band.dto.dokumente.map(d => d.handle)
                    .filter(h => h !== undefined)
                    .map(h => h + '')
                    .map(h => pdfDokumentService.get(h));
                const result = yield Promise.all(allPromises);
                this.pdfDokumente.value = result.map((p, i) => {
                    const dok = band.dto.dokumente[i];
                    p.name = dok.name + '';
                    return {
                        dokId: dok.id,
                        pdf: p,
                    };
                });
                console.log('loaded data:', this.pdfDokumente.value);
                if (this.pdfDokumente.value.length == 0) {
                    this.aktuelleSeite.value = undefined;
                }
                else {
                    this.aktuelleSeite.value = aktuelleSeite ? aktuelleSeite : { dokumentId: this.pdfDokumente.value[0].pdf.id, seite: 0 };
                }
            }
        });
    }
    reloadData(aktuelleSeite) {
        const item = this.selectedBand.value;
        if (item) {
            item.refresh().then(() => this.loadData(item, aktuelleSeite));
        }
    }
    openCreateAufgabeDialog(dokumentId) {
        this.openDialog.value = this.aufgabenCreationComponentFactory.createDialog(this.aktenId, dokumentId, this.aufgabenChanged);
    }
    closeDialog() {
        this.openDialog.value = undefined;
    }
}
export class PaginiertVerakteteDokumentViewerPanelVM {
    constructor(pdfDokumentService, aktuelleSeite, pdfDokumente, aktiverViewer) {
        this.pdfDokumentService = pdfDokumentService;
        this.aktuelleSeite = aktuelleSeite;
        this.pdfDokumente = pdfDokumente;
        this.visible = new Binding('visible', () => aktiverViewer.value === ViewerPanel.VERAKTETE_DOKUMENTE, aktiverViewer);
    }
}
export class UnpaginierterBandPanelVM {
    constructor(eaktenService, pdfDokumentService, selectedBand) {
        this.dokumentePanel = new UnpaginierteDokumenteListVM(selectedBand);
        this.editorPanel = new UnpaginiertDokumentEditorPanelVM(this.dokumentePanel.dokumentSelected, eaktenService, pdfDokumentService);
    }
}
export class UnpaginierteDokumenteListVM {
    constructor(selectedBand) {
        this.dokumenteList = new ObservableArray('dokumente', []);
        this.loadingData = new BooleanProperty('loadingData', true);
        this.dokumente = this.dokumenteList;
        this.dokumentSelected = new ObjectProperty('dokumentSelected');
        this.selectedBand = selectedBand;
        this.selectedBand.onValueChange(v => this.loadData(v));
    }
    loadData(band) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.loadingData.value = true;
                if (band === undefined) {
                    this.dokumenteList.clear();
                    this.dokumentSelected.value = undefined;
                }
                else {
                    const previousDokumentSelected = this.dokumentSelected.value;
                    this.dokumenteList.setAll(band.dto.dokumente);
                    // restore previous selection if the Dokument still exists
                    if (previousDokumentSelected !== undefined) {
                        const index = this.dokumenteList.findIndex(d => d.id === previousDokumentSelected.id);
                        if (index >= 0) {
                            this.dokumentSelected.value = this.dokumenteList.itemAt(index);
                        }
                        else {
                            this.dokumentSelected.value = undefined;
                        }
                    }
                }
            }
            finally {
                this.loadingData.value = false;
            }
        });
    }
    reloadData() {
        const item = this.selectedBand.value;
        if (item) {
            item.refresh().then(() => this.loadData(item));
        }
        else {
            this.loadData(item);
        }
    }
}
export class UnpaginiertDokumentEditorPanelVM {
    constructor(dokument, eaktenService, pdfDokumentService) {
        this.isLoading = new BooleanProperty('isLoading', false);
        this.canSelectEditable = new BooleanProperty('canSelectEditable', false);
        this.editable = new BooleanProperty('editable', false);
        this.pdfDocument = new ObjectProperty('pdfDocument');
        this.onlyOfficeConfig = new ObjectProperty('onlyOfficeConfig');
        this.dokument = dokument;
        this.eaktenService = eaktenService;
        this.pdfDokumentService = pdfDokumentService;
        dokument.onValueChange(d => this.loadEditor(d, this.editable.value));
        this.editable.onValueChange(editable => this.isLoading.value || this.loadEditor(this.dokument.value, editable));
    }
    loadEditor(dokument, editable) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.isLoading.value = true;
                this.pdfDocument.value = undefined;
                this.onlyOfficeConfig.value = undefined;
                if (dokument === undefined) {
                    this.canSelectEditable.value = false;
                    this.editable.value = false;
                }
                else {
                    this.canSelectEditable.value = false; // only enabled later if the returned editor is known to support editing
                    const editor = yield this.eaktenService.getDokumentEditor(dokument.id, editable);
                    if (editor.pdfViewer !== undefined) {
                        this.pdfDocument.value = yield this.pdfDokumentService.get(editor.pdfViewer.handle);
                        this.editable.value = false; // editor is always view-only -> reflect status in property
                    }
                    else if (editor.onlyOfficeEditorConfig !== undefined) {
                        this.onlyOfficeConfig.value = editor.onlyOfficeEditorConfig;
                        this.canSelectEditable.value = true;
                    }
                }
            }
            catch (e) {
                console.warn('failed to retrieve document editor, document type probably not supported by the server', e);
            }
            finally {
                this.isLoading.value = false;
            }
        });
    }
}
export var DokumentTyp;
(function (DokumentTyp) {
    DokumentTyp[DokumentTyp["PDF"] = 0] = "PDF";
    DokumentTyp[DokumentTyp["OFFICE"] = 1] = "OFFICE";
    DokumentTyp[DokumentTyp["UNKNOWN"] = 2] = "UNKNOWN";
})(DokumentTyp || (DokumentTyp = {}));
